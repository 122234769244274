import { LocationsAndAggregations, SiteProps } from '@zupr/types/next'
import Frontoffice from '@zupr/web/src/js/frontoffice/components/base'
import Providers from '@zupr/web/src/js/frontoffice/providers'
import Locations from '@zupr/web/src/js/frontoffice/screens/locations/list'

import { getLocationsAndAggregations } from '../../helpers/locations'
import { getProps } from '../../helpers/props'


interface Props extends SiteProps {
    locationsAndAggregations: LocationsAndAggregations
}

export async function getServerSideProps({ req, params, query }): Promise<{ props: Props }> {
    const siteProps = await getProps({ req, params, query })

    const locationsAndAggregations = await getLocationsAndAggregations({
        query: siteProps.query,
        params: {
            shopping_areas: siteProps.domain.slug,
            classification: params.classification,
        },
    }, req)

    return {
        props: {
            ...siteProps,
            locationsAndAggregations
        },
    }
}

const Index = ({
    locationsAndAggregations,
    ...siteProps
}: Props) => (
    <Providers {...siteProps}>
        <Frontoffice>
            <Locations {...locationsAndAggregations} />
        </Frontoffice>
    </Providers>
)

export default Index
